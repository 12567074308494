import { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import "../../App.css";
import SchoolService from "../../services/SchoolService";
import moment from "moment";

const CardList = () => {
  const [products, setProducts] = useState<any>();
  const schoolService = new SchoolService();
  const [selectedRow, setSelectedRow] = useState([]);

  const getCardList = async () => {
    const { response, error } = await schoolService.getCardList();

    if (response?.data?.status === true) {
      setProducts(response?.data.data);
    } else {
      console.log(response?.message, error);
    }
  };

  useEffect(() => {
    getCardList();
  }, []);

  const baskiIcinDosyalariIndirHandle = async () => {
    const ids: Number[] = [];

    if (selectedRow.length < 1) {
      alert("Baskı için listeden seçim yapınız");

      return;
    } else {
      selectedRow.map((t: any) => ids.push(Number(t.id)));

      const { response, error } = await schoolService.getCardListExcell(ids);
      if (response?.status === 200) {
        var url = window.URL.createObjectURL(response.data);
        var a = document.createElement("a");
        a.href = url;
        a.download = "Baskı Listesi.zip";
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
    }
  };

  const baskiyaGonderilmeTarihiTemplate = (rowData: any) => {
    if (rowData.baskiyaGonTarihi == null) {
      return <span></span>;
    }
    const result = moment(rowData.baskiyaGonTarihi).format("MM-DD-YYYY HH:mm");
    return <span>{rowData.baskiyaGonTarihi}</span>;
  };
  const islemTarihiTemplate = (rowData: any) => {
    if (rowData.islemTarihi == null) {
      return <span></span>;
    }
    const result = moment(rowData.islemTarihi).format("MM-DD-YYYY HH:mm");
    return <span>{result}</span>;
  };

  return (
    <div className="bg-yellow-100 h-full">
      <p>Kart Listele Sayfası</p>

      <div>
        <Button
          label="Baskıya İçin Dosyaları Al"
          onClick={baskiIcinDosyalariIndirHandle}
          className=""
        />
      </div>
      <div className="m-5">
        <DataTable
          value={products}
          loading={!products}
          selection={selectedRow}
          onSelectionChange={(e) => setSelectedRow(e.value)}
          dataKey="id"
        >
          <Column
            selectionMode="multiple"
            headerStyle={{ width: "3em" }}
          ></Column>
          <Column field="okulAdi" header="Okul Adı" sortable></Column>
          <Column field="kartNo" header="Kart No" sortable></Column>
          <Column field="alanYazisiBirinci" header="Üst Alan"></Column>
          <Column field="alanYazisiIkinci" header="Orta Alan"></Column>
          <Column field="alanYazisiUcuncu" header="Alt Alan"></Column>
          <Column body={islemTarihiTemplate} header="IslemTarihi"></Column>
          <Column
            header="Baskıya Gön. Tarihi"
            body={baskiyaGonderilmeTarihiTemplate}
          ></Column>
        </DataTable>
      </div>
    </div>
  );
};
export default CardList;

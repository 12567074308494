import axiosUtily from "../extended-tools/axiosUtily";
import ConsoleLog from "../utilities/ConsoleLog";
import axios from "axios";

export default class SchoolService {
  //
  async getCardList() {
    ConsoleLog("Fetchin getCardList");
    try {
      const response = await axiosUtily().get("api/school/CardList");
      return { response };
    } catch (error) {
      ConsoleLog("error => " + error);
      return error;
    }
  }
  //
  async getCardListExcell(ids: Number[]) {
    ConsoleLog("Fetchin getCardList");
    try {
      const formData = new FormData();
      formData.append(`baskiIdleri`, ids.toString());

      const response = await axiosUtily().post(
        "api/school/CardList/Excell",
        formData,
        {
          responseType: "blob",
        }
      );

      console.log("service response => ", response);
      return { response };
    } catch (error) {
      ConsoleLog("error => " + error);
      return { error };
    }
  }
}

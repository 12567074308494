import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { CardListPage } from "./screens/card";

export default function App() {
  return (
    <Router>
      <div className="flex h-screen flex-col ">
        <nav className="bg-red-200  ">
          <ul className="flex m-5 space-x-4">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/users">Users</Link>
            </li>
          </ul>
        </nav>

        <div className="h-full m-5 bg-gray-100">
          <Switch>
            <Route path="/about" exact>
              <About />
            </Route>
            <Route path="/users" exact>
              <Users />
            </Route>
            <Route path="/" exact>
              <CardListPage />
            </Route>
            <Route path="*" exact>
              <Page404 />
            </Route>
          </Switch>
        </div>
      </div>
    </Router>
  );
}

function About() {
  return <h2>About</h2>;
}

function Users() {
  return <h2>Users</h2>;
}
function Page404() {
  return <h2>Sayfa yok</h2>;
}

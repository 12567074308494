import axios from "axios";
import https from "https";

// export default axios.create({
//   baseURL: `http://192.168.1.222:5000/api/`
// });

const axiosUtily = () => {
  const instance = axios.create({
    baseURL: `http://192.168.43.185:5000/`,
    withCredentials: false,
    headers: {
    },
  });

  return instance;
};

export default axiosUtily;
